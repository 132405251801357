import React from "react";
import { MESSAGETYPE, STATUS } from "src/contexts/store/appReducer";

export function VideoCase({ text, type, timestamp, status }) {
  text = !!text && text.type === "cards" ? JSON.stringify(text) : text;

  const isVideo = /\.(mov|MOV|mp4|avi)/g.test(text);
  const isYoutube = /youtu.be|youtube/.test(text);

  function getFixedYoutubeLink() {
    const isNormalLink = /youtube/.test(text);
    const youtubeVideoId = text.substring(isNormalLink ? 32 : 17, text.length);
    return `https://youtube.com/embed/${youtubeVideoId}`;
  }

  return (
    <div className="message-video">
      <p
        className={`message-text ${
          type === MESSAGETYPE.OUTGOING ? "message-outgoing" : ""
        }`}
      >
        {isVideo ? (
          <video width="240" controls>
            <source src={text} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
        ) : isYoutube ? (
          <iframe
            title="video"
            height="315"
            src={getFixedYoutubeLink()}
            className="message-video-youtube"
            allow="camera *; microphone *; autoplay *"
          ></iframe>
        ) : (
          text
        )}
      </p>
      <p className="message-info message-info-video">
        <span className="message-time">
          {new Date(timestamp).getHours().toString().padStart(2, "0") +
            ":" +
            new Date(timestamp).getMinutes().toString().padStart(2, "0")}
        </span>
        <span
          className={(() => {
            switch (status) {
              case STATUS.SENT:
                return "message-status sent";
              case STATUS.RECEIVED:
                return "message-status read";
              case STATUS.ERROR:
              case STATUS.SENDING:
                return "message-status sending";
              default:
                return "";
            }
          })()}
        ></span>
      </p>
    </div>
  );
}
