import React from "react";
import { usePostMessage } from "src/hook/usePostMessage";

import "./IFrame.scss";

export function IFrame({ src, setShowIframe, setIsOpenIframe }) {
  function displayMessage(evt) {
    if (evt.data === "close-pwa") {
      setShowIframe(false);
      setIsOpenIframe(false);
    }
  }

  usePostMessage({ displayMessage });

  return (
    <div className="container">
      <iframe
        className="iframe"
        src={src}
        title="iframe"
        allow="camera *; microphone *; autoplay *"
        style={{
          outline: "none",
          border: "none",
          borderTop: "1px solid gray",
        }}
      />
    </div>
  );
}
