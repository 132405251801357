import React, { useEffect, useMemo, useState } from "react";
import { SET_USER } from "../../../contexts/store/appReducer";
import "./Text.scss";
import parse from "html-react-parser";
import { IFrame } from "./Iframe";
import { TextComponent } from "./Text";
import {
  formateMessageToCard,
  formatMessageText,
  isValidHref,
} from "../../../utils/formatCardText";
import { VideoCase } from "../Video/Video";

export default function Text(props) {
  try {
    let propsValue = props;
    const { msg } = propsValue;

    if (msg.contentType === "payload") {
      const payload =
        typeof msg.text === "string" ? msg.text : JSON.stringify(msg.text);

      propsValue = {
        ...props,
        msg: {
          ...msg,
          contentType: "text",
          text: "##NOSHOW##" + payload,
        },
      };
    } else if (/.mp4$|.avi$/.test(msg.text)) {
      return <VideoCase {...msg} />;
    }

    return TextSimple(propsValue);
  } catch (error) {
    console.log(error.message);
    return <>Erro ao crair texto</>;
  }
}

export function TextSimple(props) {
  const { msg, lastMessage, setIsOpenIframe, type } = props;

  const typeValue = useMemo(() => {
    if (type === "cards") return "card";

    if (typeof msg.text === "object") return "object";
  }, [msg.text, type]);

  const text = useMemo(() => {
    try {
      if (type === "cards") return formateMessageToCard(msg);
      const text = formatMessageText(msg.text);

      if (/<html/.test(text)) return text;

      return parse(text);
    } catch (err) {
      return msg.text;
    }
  }, [msg, type]);

  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    const currentUserId = localStorage.getItem("userId");
    let userId = currentUserId;

    if (/##USER_ID##/gi.test(text)) {
      userId = text.split("##USER_ID##")[1] ?? "";
    }

    if (currentUserId !== userId) {
      localStorage.setItem("userId", userId);
    }
  }, [text]);

  useEffect(() => {
    if (lastMessage && isValidHref(msg.text)) {
      setShowIframe(true);
      setIsOpenIframe(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    type !== "cards" &&
    !!msg.text &&
    typeof msg.text.startsWith === "function" &&
    msg.text.startsWith(SET_USER)
    // &&
    // process.env.REACT_APP_STAGE !== "dev"
  ) {
    return <></>;
  }

  if (/##NOSHOW##/gi.test(text) || /##USER_ID##/gi.test(text)) return <></>;

  if (showIframe && lastMessage) {
    return (
      <IFrame
        src={msg.text}
        setShowIframe={setShowIframe}
        setIsOpenIframe={setIsOpenIframe}
        allow="camera *; microphone *; autoplay *"
      />
    );
  }

  return (
    <TextComponent
      originalMsg={msg.text}
      text={text}
      type={typeValue}
      timestamp={msg.timestamp}
      status={msg.status}
    />
  );
}
