import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IconButtonArrowLeft } from "src/components/ButtonArrowLeft";
import LoadingCircle from "src/components/Loading/Loading";
import OrchestratorService from "src/services/OrchestratorService";
import { validLink } from "src/utils/formatCardText";
import { PayloadReceivedProps } from "../types";

export function IFramePayload({
  items,
  onClose,
  setShow,
  userId,
}: PayloadReceivedProps) {
  const node = React.useRef<any>(null);
  const orchestratorService = OrchestratorService.Instance;

  const innerHTML = useMemo(() => {
    return items;
  }, [items]);

  const isValidSource = useMemo(() => {
    return validLink(innerHTML);
  }, [innerHTML]);

  useEffect(() => {
    const iframe = node?.current;
    const iframeDoc = !!iframe
      ? iframe.contentDocument || iframe.contentWindow.document
      : undefined;

    if (!!iframeDoc && innerHTML) {
      if (isValidSource) node.current.src = innerHTML ?? "";
      else iframeDoc.body.innerHTML = innerHTML ?? "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  const [fetching, setFetching] = useState(false);

  const fetchNotifyWebhook = useCallback(async () => {
    try {
      setFetching(true);
      const data = JSON.stringify({
        type: "iframe",
        payload: "close",
      });

      localStorage.setItem("userId", userId ?? "");

      console.log("onClose", onClose);

      if (!!onClose) {
        await orchestratorService.sendNotifyWebhook({
          id: userId,
          event: onClose,
          eventParameters: {
            msg: "payload",
            payload: data,
          },
        });
      }

      setFetching(false);
      setShow(false);
    } catch (err) {
      setFetching(false);
      setShow(false);
    }
  }, [onClose, orchestratorService, setShow, userId]);

  const renderIframe = () => {
    return (
      <iframe
        className="iframe"
        ref={node}
        title="iframe"
        style={{
          outline: "none",
          border: "none",
          borderTop: "1px solid gray",
        }}
        width="560"
        height="315"
        frameBorder="0"
        allow="accelerometer *; autoplay *; clipboard-write *; encrypted-media *; gyroscope *; picture-in-picture *; camera *"
      />
    );
  };

  return (
    <div className="container">
      <button
        className="btn-back"
        onClick={() => {
          fetchNotifyWebhook();
        }}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButtonArrowLeft /> Voltar
      </button>
      {!!fetching && <LoadingCircle />}
      {renderIframe()}
    </div>
  );
}
