/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AudioRecorderProvider } from "src/hook/audioRecorder";
import {
  CONTENTTYPE,
  FILETYPE,
  getMessages,
  sendFile,
  sendLocation,
  sendMessage,
  sendRegisterDevice,
  SET_USER,
} from "../../contexts/store/appReducer";

import {
  Footer,
  Header,
  LoadingCircle,
  MessagesList,
  NotAuthorized,
} from "../../components";

import { toast } from "react-toastify";
import SystemOffline from "../SystemOffline";
import "./ChatView.scss";

function createFileFromBlob(base64Audio) {
  let blobFile, mimeType;

  if (base64Audio instanceof Blob) {
    blobFile = base64Audio;
    mimeType = base64Audio.type || "audio/wav";
  } else {
    base64Audio = base64Audio.startsWith("data:audio")
      ? base64Audio
      : `data:audio/wav;base64,${base64Audio}`;

    let arr = base64Audio.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = Buffer.from(arr[1], 'base64').toString('latin1')

    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    mimeType = mime;
    blobFile = new Blob([u8arr], { type: mimeType });
  }

  const audioName = `${new Date().toISOString()}.wav`;
  return new File([blobFile], audioName, { type: mimeType });
}

const ChatView = (props) => {
  const {
    getMessages,
    sendMessage,
    sendFile,
    isLoading,
    messages,
    suggestions,
    sendLocation,
    notAuthorized,
    isSystemOffline,
    sendRegisterDevice,
  } = props;

  const [isCopyAllowed, setIsCopyAllowed] = useState(false)

  useEffect(() => {
    getMessages();
  }, []);

  useEffect(() => {
    if (props.initialMessage) {
      sendUserData();
    }
  }, [props.initialMessage]);

  const sendInitialMessage = (msg, type) => {
    if (sessionStorage.getItem("initialMessage") !== "1" && !!msg) {
      sessionStorage.setItem("initialMessage", "1");
      sendMessage(msg, type);
    }
  };

  const sendUserData = () => {
    const displayMessage = localStorage.getItem("displayMessage");

    const hash = window.location.hash.replace("#/", "");

    if (displayMessage !== hash && !!hash) {
      sessionStorage.setItem("initialMessage", "0");
      localStorage.setItem("displayMessage", hash);
      sendInitialMessage(SET_USER + hash);
    } else {
      if (props.messages.length === 0) {
        sendMessage(SET_USER + "inicio");
      }
    }
  };

  const copyPermissionVerify = async () => {
    try {
      const copyPermission = await navigator.permissions.query({
        name: "clipboard-read",
      });

      return copyPermission.state !== "denied";
    } catch (error) {
      return false
    }
  }

  window.updatePid = (v) => {
    localStorage.setItem("pid", v);
    getMessages(true);
  };

  window.sendAudio = (base64Audio) => {
    const file = createFileFromBlob(base64Audio);
    sendFile(file, FILETYPE.AUDIO, false);
  };

  window.asyncSendAudio = (base64Audio, callback) => {
    const file = createFileFromBlob(base64Audio);
    sendFile(file, FILETYPE.AUDIO, false, callback);
  };

  window.sendTextMessage = (text, callback) => {
    sendMessage(text, CONTENTTYPE.TEXT, callback);
  };

  window.sendRegisterDevice = (token) => {
    sendRegisterDevice(token);
  };

  window.copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    window.openToast(
      "Mensagem copiada para a área de transferência",
      "warn",
      5000
    );
  };

  window.openToast = (text, type = "warn", autoClose = 3500) => {
    toast[type](text, {
      position: "top-center",
      autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  window.isIframe = () => {
    return window.top !== window.self;
  };

  // eslint-disable-next-line no-unused-vars
  const renderBody = () => {
    let renderItem = (
      <MessagesList
        onMessage={sendMessage}
        onRegisterDevice={sendRegisterDevice}
        messages={messages}
        extraMargin={suggestions.length > 0}
        groupMessages={props.groupMessages}
        isCopyAllowed={isCopyAllowed}
      />
    );
    if (isLoading) renderItem = <LoadingCircle />;

    return (
      <AudioRecorderProvider>
        {renderItem}
        <Footer
          onLocation={sendLocation}
          onMessage={sendMessage}
          onFile={sendFile}
          suggestions={suggestions}
        />
      </AudioRecorderProvider>
    );
  };

  useEffect(() => {
    if (!notAuthorized) {
      const BID = localStorage.getItem("bid");
      const TOKEN = localStorage.getItem("token");
      if (!BID || !TOKEN) {
        localStorage.removeItem("bid");
        getMessages();
      }
    }
  }, [notAuthorized]);

  useEffect(() => {
    console.log("systemOffline", isSystemOffline);
  }, [isSystemOffline]);

  useEffect(() => {
    copyPermissionVerify().then((isAllowed) => {
      setIsCopyAllowed(isAllowed);
    });
  }, []);

  return (
    <div className="appcontainer">
      <div
        id="headerContainer"
        style={{
          position: "sticky",
          top: "0px",
          height: "60px",
          width: "100%",
					zIndex: 5
        }}
      >
        <Header />
      </div>
      {notAuthorized ? (
        <NotAuthorized getMessages={getMessages} />
      ) : isSystemOffline ? (
        <SystemOffline />
      ) : (
        renderBody()
      )}
    </div>
  );
};

const mapStateToProps = (state) => state.app;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getMessages, sendMessage, sendFile, sendLocation, sendRegisterDevice },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ChatView);
